<template>
  <div class="text-center">
    <v-menu
        v-model="menu"
        :close-on-content-click="false"
        :nudge-width="200"
        offset-x
    >
      <template v-slot:activator="{ on, attrs }">
        <v-icon v-bind="attrs" v-on="on">mdi-dots-horizontal-circle-outline
        </v-icon>
      </template>

      <v-card>
        <v-list>
          <v-list-item @click="edit">
            <v-list-item-action>
              <v-icon>mdi-square-edit-outline</v-icon>
            </v-list-item-action>
            <v-list-item-title>Edit</v-list-item-title>
          </v-list-item>

          <v-dialog v-model="dialog" width="500">
            <template v-slot:activator="{ on, attrs }">
              <v-list-item v-bind="attrs" v-on="on">
                <v-list-item-action>
                  <v-icon>mdi-trash-can-outline</v-icon>
                </v-list-item-action>
                <v-list-item-title>Delete</v-list-item-title>
              </v-list-item>
            </template>
            <v-card>
              <v-card-title></v-card-title>
              <v-card-text>
                <div class="text-h6">Are you sure you want to delete the project?</div></v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="remove">Yes</v-btn>
                <v-btn color="primary" text @click="dialog = false">No</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-list>
      </v-card>
    </v-menu>
  </div>
</template>



<script>
export default {
  name:"PopoverDots",
  data: () => ({
    fav: true,
    menu: false,
    message: false,
    hints: true,
    dialog: false
  }),
  methods: {
    edit() {
      this.$emit('edit')
    },
    remove() {
      this.dialog = false
      this.$emit('remove')
    }
  }
}
</script>