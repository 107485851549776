<template>
  <v-card class="mx-auto" width="288" max-width="288" outlined
          @mouseover="isHovering = true"
          @mouseout="isHovering = false"
          :class="isHovering ? 'elevation-4' : '' ">
    <v-img class="white--text align-end" height="114px" :src="imageSrc" />
    <v-row class="mt-4 p-0 mx-0 row-height">
      <v-card-subtitle class="fz-14 pl-4 py-0 openSans-400-l max-ch-category">{{ type }}</v-card-subtitle>
      <v-chip class="fz-12 openSans-400 chip-category ml-auto mr-4" label v-if="available">Available</v-chip>
    </v-row>

    <v-card-title class="fz-18 mt-1 pb-0 pt-1 px-4 openSans-400">
      {{title}}
    </v-card-title>

    <v-card-actions class="mt-10 py-0 mb-5 px-4">
      <v-btn v-if="training" color="primary" class="project-card-action-button px-0" text>Watch Video</v-btn>
      <v-btn v-else color="primary" class="project-card-action-button px-0" text :to="`/ilm/${ projectId }`">View Report</v-btn>
      <popover-dots class="ml-auto" v-on:edit="edit" v-on:remove="remove"></popover-dots>
      <v-dialog v-model="editDialog" max-width="290">
        <v-card>
          <v-card-title class="text-h5">
            Edit project name
          </v-card-title>
          <v-card-text>
            <v-text-field outlined v-model="localTitle"></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="editDialog = false"> Cancel</v-btn>
            <v-btn color="primary" text @click="save">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card-actions>
  </v-card>
</template>

<script>
import PopoverDots from "./PopoverDots";
export default {
  name: 'ProjectCard',
  components:{PopoverDots},
  props: {
    projectId: String,
    training: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    imageSrc: {
      type: String,
      default: ''
    },
    available: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isHovering: false,
      editDialog:false,
      localTitle:""
    }
  },
  methods: {
    onClick() {
      this.$emit('setFavorite')
    },
    edit() {
      this.localTitle = this.title
      this.editDialog = true
    },
    save() {
      this.$emit('edit', this.localTitle)
    },
    remove() {
      this.$emit('remove')
    },
  }
}
</script>

<style lang="css" scoped>

.project-card-action-button {
  font-size: 12px;
}

.max-ch-category {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 27ch;
  color: #979797 !important;
}

::v-deep .chip-category {
  height: auto;
  padding: 2px 6px;
  background: #09609F26 !important;
}

::v-deep span.chip-category span.v-chip__content {
  color:#09609F !important;
}

.row-height {
  height: 24px;
}
</style>