<template>
  <div>
    <v-container class="m-0">
      <v-row class="mx-0 px-0 col-12 justify-space-between">
        <h1 class="openSans-400 fz-24">{{ $vuetify.lang.t('$vuetify.projects.labels.myProject') }}</h1>
        <v-menu v-show="!$vuetify.breakpoint.xs" offset-y nudge-left="88" nudge-bottom="8">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" v-bind="attrs" v-on="on">
              {{ $vuetify.lang.t('$vuetify.projects.buttons.addProject') }}<v-icon class="ml-1">mdi-menu-down</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item link to="/project/creation">
              <v-list-item-title>{{ $vuetify.lang.t('$vuetify.projects.labels.intelligentLiteratureMonitoring') }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-row>
      <v-row class="mx-0 justify-start" >
        <project-card class="mb-3" v-for="project in projects" :key="project.id" :project="project" v-on:refresh="getProjects"></project-card>
      </v-row>
        <v-btn v-show="$vuetify.breakpoint.xs" color="primary" class="fab-add-project" fab>
          <v-icon>mdi-plus</v-icon>
        </v-btn>
    </v-container>
  </div>
</template>

<script>
import ProjectCard from '@/modules/projects/components/ProjectCard.vue'
import ProjectService from "@/modules/projects/services/ProjectService";
export default {

  name: 'MyProjects',

  components: { ProjectCard },

  data () {
    return {
      projects: []
    }
  },
  created() {
    this.getProjects()
  },
  methods :{
    getProjects() {
      let projects = []
      ProjectService.find().then(response => {
        projects.push({
          title: 'Learn how to add a Project',
          type: 'Training',
          training: true,
          id: "0x3dbd0747c",
          preview: 'https://i.imgur.com/4fAKt2w.png',
          link:"https://youtu.be/RKenFe_OVWQ"
        })
        if (response.data.data) {
          for (let project of response.data.data) {
            let previewImage =  'http://c.files.bbci.co.uk/D505/production/_115033545_gettyimages-1226314512.jpg'
            if (project.project_settings && project.project_settings.image) {
              previewImage = project.project_settings.image
            } else if (project.account && project.account[0].ilm_default_project_image){
              previewImage = project.account[0].ilm_default_project_image
            }
            projects.push({
              id: project.uid,
              title: project.project_name,
              type: project.project_type,
              available: project.active || false,
              preview: previewImage
            })
          }
        }
      }).catch(e => console.log(e))
      this.projects = projects
    }
  }
}
</script>

<style lang="css" scoped>

.fab-add-project {
  position: sticky;
  bottom: 5%;
  left: 80%;
}
</style>