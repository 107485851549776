<template>
  <div @click="goToILM">
    <project-card-s-b :title="project.title" :type="project.type" :image-src="project.preview"
                      :available="project.available" :training="project.training"
                      v-on:remove="remove" v-on:edit="edit" :project-id="project.id"/>
  </div>
</template>

<script>
import ProjectCardSB from "@/modules/projects/components/ProjectCardSB";
import ProjectService from "@/modules/projects/services/ProjectService";
export default {

  name: 'ProjectCard',

  components: { ProjectCardSB },
  props: {
    project: Object
  },
  methods: {
    goToILM() {
      if (this.project.training) {
        window.open(this.project.link)
      } else {
        this.$router.push(`/ilm/${ this.project.id }`)
      }
    },
    remove() {
      ProjectService.remove(this.project.id).then(
          () => {
            this.$emit('refresh')
          }
      )
    },
    edit(name) {
      ProjectService.patch(this.project.id, name, this.project.available).then(
          () => {
            this.$emit('refresh')
          }
      )
    }
  }
}
</script>

<style lang="css" scoped>
</style>